<template>
  <FmTabs class="status-tabs" :value="value" @change="change">
    <FmTabPane :title="item.label" :name="item.key" v-for="(item, index) in statusList" :key="index">
    </FmTabPane>
  </FmTabs>
</template>

<script>
export default {
  props: {
    statusList: { type: Array },
    value: {
      type: [String, Number],
      value: null
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="less">
.status-tabs {
  .fm-tabs-nav {
    border-bottom: 0;
  }
  .fm-tabs-content {
    display: none;
  }
}
</style>