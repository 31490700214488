<template>
  <SimplePage class="simple-page-norm">
    <template slot="header">
      <div class="labels">
        <div class="label active">{{title}}</div>
      </div>
    </template>
    <slot></slot>
  </SimplePage>
</template>

<script>
import SimplePage from './SimplePage'

export default {
  components: {
    SimplePage
  },
  props: {
    title: {
      type: String,
      default: '页面标题'
    }
  }
}
</script>

<style scoped lang="less">
.simple-page-norm {
  .labels {
    .label {
      border-radius: 8px 8px 0px 0px;
      background: #B3B3B3;
      box-sizing: border-box;
      display: inline-block;
      padding: 3px 24px;
      text-align: center;
      color: #FFF;
      cursor: pointer;
      transition: all .3s;
      &:hover:not(.active) {
        color: #2E6BE5;
      }
      &.active {
        cursor: default;
        background: #2E6BE5;
      }
      & + .label {
        margin-left: 1px;
      }
    }
  }
}
</style>