<style scoped lang="less">
  .simple-page {
    background-color: #F7F9FE;
    padding: 20px;
    box-sizing: border-box;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .content {
    height: 0;
    flex: 1;
    background-color: #FFF;
    box-sizing: border-box;
  }
</style>

<template>
  <div class="simple-page">
    <div class="container">
      <div class="header">
        <slot name="header"></slot>
        <slot name="action"></slot>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
